import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import VimeoPlayer from '../components/VimeoPlayer'

const theme = 'dark' //Set the color theme here

export default function Home( { data }) {
    const featuredProjects = data.allFile.edges

    return (
        <Layout theme={ theme } currentPath = '/'>

            <Helmet>
                <title>{'VOLNA'}</title>
                <meta name="description" content="We fill your void" />
                <link rel="canonical" href="https://volna-media.com" />
                <meta property="og:url" content="https://volna-media.com" />
                <meta property="og:title" content='VOLNA' />
                <meta property="og:description" content="We fill your void" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:creator" content="VOLNA" />
                <meta name="twitter:title" content='VOLNA' />
                <meta name="twitter:description" content="We fill your void" />
            </Helmet>

            <div>
                <div className="full-bleed">
                    <div className="video-highlight-fallback" />
                    <VimeoPlayer videoID="615195993"showControls = '0'/>
                </div>
                <div className="main-intro-text">
                    <span className="xl-heading">
                        We fill your void
                    </span>
                </div>
            </div>
           {
           featuredProjects.map(project  => (
               <div key={project.node.name} className="main-featured-work">
                    <a href={'/projects/' + project.node.name}>
                    <div className="full-bleed">
                        <div className="video-highlight-fallback"/>
                        <VimeoPlayer videoID={ project.node.childMdx.frontmatter.thumb } showControls = '0'/>
                    </div>
                    <div className="featured-work-info">
                        <div className="link-title">{project.node.childMdx.frontmatter.title}</div>
                        <div className="link-description">{project.node.childMdx.frontmatter.description}</div>
                    </div>
                    </a>
                </div>
            )) 
           }
        <div className="cta">
           <Link to="/works"><span className="body-large">View all works</span></Link><span className="body-large"> &rarr;</span>
        </div>
        <div className="main-about-text">
            <h2>
            VOLNA is an independent art collective shaping cutting-edge designs and spatial art using diverse media and technologies. From intricate light and kinetic objects to large-scale installations, we create unique environments that blend together physical and digital worlds 
            </h2>
        </div>
        <div className="cta">
           <Link to="/about"><span className="body-large">More about VOLNA</span></Link><span className="body-large"> &rarr;</span>
        </div>
        </Layout>
    )
}

export const query = graphql`
query FeaturedProjects {
    allFile(
    sort: {fields: childrenMdx___frontmatter___date, order: DESC}
      filter: {ext: {eq: ".mdx"}, childMdx: {frontmatter: {mainFeatured: {eq: 1}}}}
    ) {
      edges {
        node {
          id
          childMdx {
            frontmatter {
              thumb
              title
              description
            }
          }
          name
        }
      }
    }
  }
`
